import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service';
import { APP_PRIMENG_MODULE } from './shared/APP.MODULE-PRIMENG';
import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';
registerLocaleData(localeRo);

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastModule,
        APP_PRIMENG_MODULE], providers: [
        AuthService,
        AuthGuard,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        MessageService,
        { provide: LOCALE_ID, useValue: 'ro-RO' },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
